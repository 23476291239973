import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Arts from "../../assets/arts.jpg";
import Commerce from "../../assets/commerce.webp";
import Science from "../../assets/science.webp";
import { Link } from "react-router-dom";

import "./index.css";

function GridExample() {
  return (
    <Row xs={1} md={3} className="g-4 my-2 container mx-auto">
      <Col style={{ maxHeight: "30rem" }}>
        <Card className="h-100">
          <Card.Img variant="top" src={Arts} className="card-img-subject" />
          <Card.Body>
            <Card.Title>
              <Link to="/courses" className="">
                Arts
              </Link>
            </Card.Title>
            <Card.Text>
              Get in touch for admission in arts, sitting at the comfort of your
              house.We provide around 15 arts subject to pursue.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col style={{ maxHeight: "30rem" }}>
        <Card className="h-100">
          <Card.Img variant="top" src={Commerce} className="card-img-subject" />
          <Card.Body>
            <Card.Title>
              <Link to="/courses" className="">
                Commerce
              </Link>
            </Card.Title>
            <Card.Text>
              Get in touch for admission in commerce, sitting at the comfort of
              your house.We provide around 10 commerce subject to pursue.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col style={{ maxHeight: "30rem" }}>
        <Card className="h-100">
          <Card.Img variant="top" src={Science} className="card-img-subject" />
          <Card.Body>
            <Card.Title>
              <Link to="/courses" className="">
                Science
              </Link>
            </Card.Title>
            <Card.Text>
              Get in touch for admission in science, sitting at the comfort of
              your house.We provide around 10 science subject to pursue.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default GridExample;
