import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import pic1 from "../../assets/college-1.jpeg";
import pic2 from "../../assets/college-2.jpeg";
import faculty from "../../assets/faculty.jpg";
import lab from "../../assets/lab.jpg";
import library from "../../assets/library.jpeg";
import studentsClass from "../../assets/students-class.jpg";
import studentsFront from "../../assets/students-front.jpg";
import studentsGather from "../../assets/students-gather.jpg";
import studentsGatherAngle from "../../assets/students-gather-angle.jpg";

import "./index.css";

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      className="my-8-custom"
    >
      <Carousel.Item className="car-height">
        <img className="d-block w-100 img-banner" src={lab} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item className="car-height">
        <img
          className="d-block w-100 img-banner"
          src={studentsGatherAngle}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item className="car-height">
        <img
          className="d-block w-100 img-banner"
          src={faculty}
          alt="Third slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100 img-banner"
          src={studentsGather}
          alt="Fourth slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 img-banner"
          src={library}
          alt="Fifth slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 img-banner"
          src={studentsClass}
          alt="Sixth slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 img-banner"
          src={studentsFront}
          alt="Seventh slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 img-banner"
          src={pic1}
          alt="Eighth slide"
        />
        <Carousel.Caption className="d-none d-sm-block">
          <h3>
            Contact us by fillling the form or calling us on our contact number.
          </h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 img-banner"
          src={pic2}
          alt="Ninth slide"
        />
        <Carousel.Caption className="d-none d-sm-block">
          <h3>First tech-enabled degree and inter college in Lauriya block</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;
