import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import React, { useState, useEffect } from "react";
import { signInGuestUser } from "../../firebase";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { useDispatch } from "react-redux";
import "./index.css";

function ContactDetails() {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [comments, setComments] = useState("");

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "name") {
      setName(value);
    } else if (name === "mobile") {
      setMobile(value);
    } else {
      setComments(value);
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      // const payload={name,email,mobile};
      await signInGuestUser(name, email, mobile);
      const payloadToast = {
        isToast: true,
        message: {
          header: "Attention user",
          footer: "Query submitted. We will call you back!",
        },
        background: "success",
      };
      dispatch({ type: "SHOW_TOAST", payload: payloadToast });
      setValidated(false);
      setEmail("");
      setName("");
      setMobile("");
      setComments("");
    }
  };

  return (
    <div className="container my-10 border pt-3 shadow-sm  mb-5 bg-body rounded">
      <p className="m0 fw-bolder fs-2 text-center text-decoration-underline">
        Contact Form
      </p>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group controlId="formGroupName" className="my-3">
          <Form.Label>Name*</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter Name"
            name="name"
            value={name}
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            *Name required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formGroupEmail" className="my-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            value={email}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="formGroupNumber" className="my-3">
          <Form.Label>Mobile number*</Form.Label>
          <Form.Control
            type="tel"
            placeholder="Enter mobile no."
            name="mobile"
            pattern="^[0-9]{10}$"
            required
            value={mobile}
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            *valid phone number required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formGroupTextarea" className="my-3">
          <Form.Label>Inquiry</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="comments"
            value={comments}
            onChange={handleChange}
          />
        </Form.Group>
        <Button variant="primary" type="submit" size="lg">
          Submit
        </Button>
      </Form>
      <Row className="g-2 my-2 mt-5 border-top border-warning pt-5">
        <Col md>
          <Card>
            <Card.Body>
              <div>
                <p className="fs-3 fw-bolder">Office Location -:</p>
                <p>
                  <i class="fa-solid fa-house me-3"></i>Rajkeshwar Rao
                  Nandangarh college,Bijabania, Lauriya,845453{" "}
                </p>
                <p>
                  <i class="fa-solid fa-phone me-3"></i>Call Us:- 7979083290,
                  8210498156
                </p>
                <p>
                  <i class="fa-solid fa-envelope me-3"></i>Email Us-:
                  support@rkrcollege.org | rajkeshwarNandan@gmail.com
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md>
          <Card>
            <Card.Body>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3555.880697627341!2d84.4436032!3d26.970677100000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399363cea612b2e5%3A0x454bff7c39d18d66!2sRajkeshwar%20Rao%20Nandangarh%20Degree%20College%20Bijbaniya%20lauriya!5e0!3m2!1sen!2sus!4v1735644494301!5m2!1sen!2sus"
                className="map-body"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ContactDetails;
