import React from "react";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <section
      id="footer"
      className="w-100 bg-dark bg-gradient p-4"
      style={{ minHeight: "300px" }}
    >
      <div className="row text-white fw-bold">
        <div className="col-md-3 col-xs-12 mb-4">
          <h4 class="text-underline ">Important Links</h4>
          <ul class="list-unstyled h6 ">
            <li class="my-3 text-start">
              {" "}
              <a
                href="https://ndl.iitkgp.ac.in/"
                target="_blank"
                class="text-decoration-none text-white "
              >
                {" "}
                <i class="fa fa-angle-double-right me-2"></i> NDL
              </a>{" "}
            </li>
            <li class="my-3 text-start">
              {" "}
              <a
                href="http://egranthalaya.nic.in/"
                target="_blank"
                class="text-decoration-none text-white"
              >
                {" "}
                <i class="fa fa-angle-double-right me-2"></i> e-Granthalaya
              </a>{" "}
            </li>
            <li class="my-3 text-start">
              {" "}
              <a
                href="https://ugc.ac.in/"
                target="_blank"
                class="text-decoration-none text-white"
              >
                {" "}
                <i class="fa fa-angle-double-right me-2"></i>UGC
              </a>{" "}
            </li>
            <li class="my-3 text-start">
              {" "}
              <a
                href="https://nmeict.ac.in/"
                target="_blank"
                class="text-decoration-none text-white"
              >
                {" "}
                <i class="fa fa-angle-double-right me-2"></i>NMEICT
              </a>{" "}
            </li>
            <li class="my-3 text-start">
              {" "}
              <a
                href="http://www.naac.gov.in/index.php/en/"
                target="_blank"
                class="text-decoration-none text-white"
              >
                {" "}
                <i class="fa fa-angle-double-right me-2"></i>NAAC
              </a>{" "}
            </li>
          </ul>
        </div>
        <div className="col-md-3 col-xs-12 mb-4">
          <h4 class="text-underline ">Follow Us</h4>
          <ul class="list-unstyled h6 ">
            <li class="my-3 text-start">
              {" "}
              <a
                href="https://www.facebook.com/p/Rajkeshwar-Rao-Nandangarh-Degree-College-Bijbaniya-Lauriya-100084288455520/"
                target="_blank"
                class="text-decoration-none text-white "
              >
                {" "}
                <i class="fa-brands fa-facebook me-2"></i>Facebook
              </a>{" "}
            </li>
            <li class="my-3 text-start">
              {" "}
              <a
                href="#twitter"
                target="_blank"
                class="text-decoration-none text-white"
              >
                {" "}
                <i class="fa-brands fa-twitter me-2"></i>Twitter
              </a>{" "}
            </li>
            <li class="my-3 text-start">
              {" "}
              <a
                href="https://www.instagram.com/rajkeshwarraonandangarhcollege/"
                target="_blank"
                class="text-decoration-none text-white"
              >
                {" "}
                <i class="fa-brands fa-instagram me-2"></i>Instagram
              </a>{" "}
            </li>
            <li class="my-3 text-start">
              {" "}
              <a
                href="https://www.youtube.com/@rajkeshwarnandangarh9105"
                target="_blank"
                class="text-decoration-none text-white"
              >
                {" "}
                <i class="fa-brands fa-youtube me-2"></i>Youtube
              </a>{" "}
            </li>
          </ul>
        </div>
        <div className="col-md-3 col-xs-12 mb-4">
          <h4 class="text-underline ">Other Links</h4>
          <ul class="list-unstyled h6 ">
            <li class="my-3 text-start">
              {" "}
              <Link to="/privacy" class="text-decoration-none text-white ">
                Privacy Policy
              </Link>{" "}
            </li>
          </ul>
        </div>
        <div className="col-md-3 col-xs-12 mb-4">
          <h4 class="text-underline">Address</h4>
          <ul class="list-unstyled h6 text-start">
            <li class="my-3 ">
              {" "}
              <i class="fa-solid fa-house me-1"></i>Rajkeshwar Rao Nandangarh
              college,Bijabania, Lauriya,845453{" "}
            </li>
            <li class="my-3">
              {" "}
              <i class="fa-solid fa-phone me-1"></i>7979083290, 8210498156{" "}
            </li>
            <li class="my-3">
              {" "}
              <i class="fa-solid fa-envelope me-1"></i>
              support@rkrcollege.org | rajkeshwarNandan@gmail.com
            </li>
          </ul>
        </div>
      </div>
      <div className="border border-1 border-secondary mt-2 text-center"></div>
      <div className="text-center text-white mt-4">
        <p>© Copyright 2022, Rajkeshwar Rao Group Of College</p>
      </div>
    </section>
  );
}
